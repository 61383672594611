 .editor-container {
     align-self: flex-start;
     border-radius: 2px;
     /* min-width: 600px; */
     color: var(--colorNeutralForeground1);
     position: relative;
     line-height: 20px;
     font-weight: 400;
     text-align: left;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
 }

 .editor-input {
     min-height: 10em;
     resize: none;
     font-size: var(--fontSizeBase300);
     padding: 15px 12px;
     caret-color: var(--colorNeutralForeground2Link);
 }

 .editor-input:focus {
     /* border: solid 2px var(--colorBrandBackgroundInvertedPressed);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; */
     outline: none;
 }

 .editor-input.readonly {
     min-height: 0em !important;
 }

 .editor-placeholder {
     color: var(--colorBackgroundOverlay);
     overflow: hidden;
     position: absolute;
     text-overflow: ellipsis;
     top: 15px;
     left: 12px;
     font-size: var(--fontSizeBase300);
     user-select: none;
     display: inline-block;
     pointer-events: none;
 }

 .dark .editor-placeholder {
     color: #d6d6d6;
 }

 .editor-text-bold {
     font-weight: bold;
 }

 .editor-text-italic {
     font-style: italic;
 }

 .editor-text-underline {
     text-decoration: underline;
 }

 .editor-text-strikethrough {
     text-decoration: line-through;
 }

 .editor-text-underlineStrikethrough {
     text-decoration: underline line-through;
 }

 .editor-text-code {
     background-color: rgb(240, 242, 245);
     padding: 1px 0.25rem;
     font-family: Menlo, Consolas, Monaco, monospace;
     font-size: 94%;
 }

 .dark .editor-text-code {
     background-color: #000;
 }

 .contrast .editor-text-code {
     background-color: #000;
     color: #3ff23f;
 }

 .editor-link {
     color: rgb(33, 111, 219);
     text-decoration: underline;
 }

 .dark .editor-link {
     color: #1aebff;
 }

 .contrast .editor-link {
     color: #1aebff;
 }

 .editor-code {
     background-color: rgb(240, 242, 245);
     font-family: Menlo, Consolas, Monaco, monospace;
     display: block;
     padding: 8px 8px 8px 52px;
     line-height: 1.53;
     font-size: 13px;
     margin: 0;
     margin-top: 8px;
     margin-bottom: 8px;
     tab-size: 2;
     /* white-space: pre; */
     overflow-x: auto;
     position: relative;
 }

 .contrast .editor-code {
     color: red;
 }

 .editor-code:before {
     content: attr(data-gutter);
     position: absolute;
     background-color: #eee;
     left: 0;
     top: 0;
     border-right: 1px solid #ccc;
     padding: 8px;
     color: #777;
     white-space: pre-wrap;
     text-align: right;
     min-width: 25px;
 }

 .editor-code:after {
     content: attr(data-highlight-language);
     top: 0;
     right: 3px;
     padding: 3px;
     font-size: 10px;
     text-transform: uppercase;
     position: absolute;
     color: rgba(0, 0, 0, 0.5);
 }

 .editor-tokenComment {
     color: slategray;
 }

 .editor-tokenPunctuation {
     color: #999;
 }

 .editor-tokenProperty {
     color: #905;
 }

 .editor-tokenSelector {
     color: #690;
 }

 .editor-tokenOperator {
     color: #9a6e3a;
 }

 .editor-tokenAttr {
     color: #07a;
 }

 .editor-tokenVariable {
     color: #e90;
 }

 .editor-tokenFunction {
     color: #dd4a68;
 }

 .editor-paragraph {
     margin: 0;
     margin-bottom: 8px;
     position: relative;
 }

 .editor-paragraph:last-child {
     margin-bottom: 0;
 }

 .editor-heading-h1 {
     font-size: 24px;
     color: rgb(5, 5, 5);
     font-weight: 400;
     margin: 0;
     margin-bottom: 12px;
     padding: 0;
 }

 .editor-heading-h2 {
     font-size: 15px;
     color: rgb(101, 103, 107);
     font-weight: 700;
     margin: 0;
     margin-top: 10px;
     padding: 0;
     text-transform: uppercase;
 }

 .dark .editor-heading-h2 {
     color: #d6d6d6;
 }

 .contrast .editor-heading-h2 {
     color: #000;
 }

 .editor-quote {
     margin: 0;
     margin-left: 20px;
     font-size: 15px;
     color: rgb(101, 103, 107);
     border-left-color: rgb(206, 208, 212);
     border-left-width: 4px;
     border-left-style: solid;
     padding-left: 16px;
 }

 .dark .editor-quote {
     color: #d6d6d6;
 }

 .contrast .editor-quote {
     color: #000;
 }

 .editor-list-ol {
     padding: 0;
     margin: 0;
     margin-left: 16px;
 }

 .editor-list-ul {
     padding: 0;
     margin: 0;
     margin-left: 16px;
 }

 .editor-listitem {
     margin: 8px 32px 8px 32px;
 }

 .editor-nested-listitem {
     list-style-type: none;
 }

 pre::-webkit-scrollbar {
     background: transparent;
     width: 10px;
 }

 pre::-webkit-scrollbar-thumb {
     background: #999;
 }

 .debug-timetravel-panel {
     overflow: hidden;
     padding: 0 0 10px 0;
     margin: auto;
     display: flex;
 }

 .debug-timetravel-panel-slider {
     padding: 0;
     flex: 8;
 }

 .debug-timetravel-panel-button {
     padding: 0;
     border: 0;
     background: none;
     flex: 1;
     color: #fff;
     font-size: 12px;
 }

 .debug-timetravel-panel-button:hover {
     text-decoration: underline;
 }

 .debug-timetravel-button {
     border: 0;
     padding: 0;
     font-size: 12px;
     top: 10px;
     right: 15px;
     position: absolute;
     background: none;
     color: #fff;
 }

 .debug-timetravel-button:hover {
     text-decoration: underline;
 }

 .emoji {
     color: transparent;
     background-size: 16px 16px;
     background-position: center;
     background-repeat: no-repeat;
     vertical-align: middle;
     margin: 0 -1px;
 }

 .emoji-inner {
     padding: 0 0.15em;
 }

 .emoji-inner::selection {
     color: transparent;
     background-color: rgba(150, 150, 150, 0.4);
 }

 .emoji-inner::moz-selection {
     color: transparent;
     background-color: rgba(150, 150, 150, 0.4);
 }

 .toolbar {
     container-type: inline-size;
     display: flex;
     padding: 4px;
     flex-wrap: wrap;
     justify-content: space-between;
 }

 .toolbar .toolbar-icon {
     color: var(--colorNeutralForeground2Link);
 }

 .two-part-tools {
     display: flex;
     flex-direction: row;
     align-items: flex-start;
 }

 .responsive-menus-toolbar {
     display: flex;
 }

 .responsive-buttons-toolbar {
     display: none;
 }

 /* Contenedor mediano */
 @container (min-width: 529px) {
     .responsive-menus-toolbar {
         display: none;
     }

     .responsive-buttons-toolbar {
         display: flex;
     }
 }

 /* Contenedor grande */
 @container (min-width: 600px) {
     .responsive-menus-toolbar {
         display: none;
     }

     .responsive-buttons-toolbar {
         display: flex;
     }

     .two-part-tools {
         flex-wrap: nowrap;
     }
 }

 #block-controls button:hover {
     background-color: #efefef;
 }

 #block-controls button:focus-visible {
     border-color: blue;
 }

 #block-controls span.block-type {
     background-size: contain;
     display: block;
     width: 18px;
     height: 18px;
     margin: 2px;
 }

 .dropdown {
     z-index: 5;
     display: block;
     position: absolute;
     box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
         inset 0 0 0 1px rgba(255, 255, 255, 0.5);
     border-radius: 8px;
     min-width: 100px;
     min-height: 40px;
     background-color: #fff;
 }

 .dropdown .item {
     margin: 0 8px 0 8px;
     padding: 8px;
     color: #050505;
     cursor: pointer;
     line-height: 16px;
     font-size: 15px;
     display: flex;
     align-content: center;
     flex-direction: row;
     flex-shrink: 0;
     justify-content: space-between;
     background-color: #fff;
     border-radius: 8px;
     border: 0;
     min-width: 268px;
 }

 .dropdown .item .active {
     display: flex;
     width: 20px;
     height: 20px;
     background-size: contain;
 }

 .dropdown .item:first-child {
     margin-top: 8px;
 }

 .dropdown .item:last-child {
     margin-bottom: 8px;
 }

 .dropdown .item:hover {
     background-color: #eee;
 }

 .dropdown .item .text {
     display: flex;
     line-height: 20px;
     flex-grow: 1;
     width: 200px;
 }

 .dropdown .item .icon {
     display: flex;
     width: 20px;
     height: 20px;
     user-select: none;
     margin-right: 12px;
     line-height: 16px;
     background-size: contain;
 }

 .link-editor {
     position: absolute;
     z-index: 1000;
     margin-left: 100px;
     margin-top: -6px;
     max-width: 300px;
     width: 100%;
     opacity: 0;
     background-color: #fff;
     box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
     border-radius: 8px;
     transition: opacity 0.5s;
 }

 .link-editor .link-input {
     display: block;
     min-height: 2.3em;
     width: calc(100% - 24px);
     box-sizing: border-box;
     margin: 8px 12px;
     padding: 8px 12px;
     border-radius: 15px;
     background-color: #eee;
     font-size: 15px;
     color: rgb(5, 5, 5);
     border: 0;
     outline: 0;
     position: relative;
     font-family: inherit;
 }

 .link-editor div.link-edit {
     background-image: url(/public/icons/pencil-fill.svg);
     background-size: 16px;
     background-position: center;
     background-repeat: no-repeat;
     width: 35px;
     vertical-align: -0.25em;
     position: absolute;
     right: 0;
     top: 0;
     bottom: 0;
     cursor: pointer;
 }

 .link-editor .link-input a {
     color: rgb(33, 111, 219);
     text-decoration: none;
     display: block;
     white-space: nowrap;
     overflow: hidden;
     margin-right: 30px;
     text-overflow: ellipsis;
 }

 .link-editor .link-input a:hover {
     text-decoration: underline;
 }

 .link-editor .button {
     width: 20px;
     height: 20px;
     display: inline-block;
     padding: 6px;
     border-radius: 8px;
     cursor: pointer;
     margin: 0 2px;
 }

 .link-editor .button.hovered {
     width: 20px;
     height: 20px;
     display: inline-block;
     background-color: #eee;
 }

 .link-editor .button i,
 .actions i {
     background-size: contain;
     display: inline-block;
     height: 20px;
     width: 20px;
     vertical-align: -0.25em;
 }

 div.remove-icon {
     background-color: rgba(242, 242, 242, 0.8);
     background-image: url(/public/icons/remove.svg);
     background-size: 60%;
     background-repeat: no-repeat;
     background-position: center;
     width: 35px;
     height: 35px;
     border-radius: 8px;
     position: absolute;
     left: 10px;
     top: 10px;
     cursor: pointer;
     transition: background-color 0.2s ease;
 }

 div.remove-icon:hover {
     background-color: #e0e0e0;
 }