* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app-body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    box-sizing: border-box;
}

.mr-2 {
    margin-right: 0.2em;
}

.ml-2 {
    margin-left: 0.2em;
}

.mb-2 {
    margin-bottom: 0.2em;
}

.mb-4 {
    margin-bottom: 1em;
}

.ml-4 {
    margin-left: 1.4em;
}

.mt-4 {
    margin-top: 1em;
}

.mt-6 {
    margin-top: 2em;
}

.mt-8 {
    margin-top: 4em;
}

.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 1rem;
    margin-bottom: 20rem;
}

.dashboard-card {
    width: 100%;
    min-height: 200px;
    height: 100%;
}

/* Breakpoint para pantallas medianas (768px y arriba) */
@media (min-width: 768px) {
    .card-20 {
        width: 20%;
    }

    .card-30 {
        width: 30%;
    }

    .card-40 {
        width: 40%;
    }

    .card-50 {
        width: 50%;
    }

    .card-60 {
        width: 60%;
    }

    .card-70 {
        width: 72%;
    }

    .card-80 {
        width: 80%;
    }

    .card-90 {
        width: 90%;
    }

    .card-100 {
        width: 100%;
    }
}

.icon-container {
    align-items: center;
    display: flex;
}

.announcement-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32em;
    align-self: center;
}

.acction-msg-container {
    justify-items: center;
    gap: 2em;
    display: grid;
}

.horizontal-content {
    display: flex;
    flex-direction: row;
}

.layout-page-wizard {
    display: flex;
}

.grecaptcha-badge {
    visibility: hidden;
}

.wizard-welcome {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.wizard-sidebar {
    transition: transform 0.3s ease-in-out;
    background-color: var(--colorNeutralBackground2);
    padding-left: 1em;
    display: flex;
    flex-direction: column;
    position: fixed;
    border-right: 1px solid var(--colorNeutralStroke2);
    width: 16rem;
    z-index: 600;
    height: 100vh;
}

.wizard-wrapper {
    width: calc(100% - 16rem);
    margin-left: 16rem;
    margin-top: 1%;
    padding-bottom: 15%;
    background-color: var(--colorNeutralBackground2);
}

.wizard-bottom-bar {
    position: fixed;
    bottom: 0;
    width: calc(100% - 16rem);
    height: 100px;
    border-top: 1px solid var(--colorNeutralStroke2);
    background-color: var(--colorNeutralBackground2);
    z-index: 99;
    line-height: 1.42857143;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
}

.payment-method {
    width: 100%;
    flex: 1;
}

.payment-method-container {
    max-width: 42rem;
    margin-top: 3rem;
    margin-right: 2rem;
}

.payment-method-update-container {
    max-width: 33.5rem;
    margin-top: 2rem;
    margin-bottom: 5rem;
}

.payment-method .summary-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.payment-method .payment-method-summary {
    background-color: var(--colorNeutralBackground4);
    border-radius: 0.5rem;
    padding: 1rem;
}

.payment-method .info-description {
    font-size: 0.775rem;
    color: var(--colorNeutralForeground4);
}

.btn-container {
    padding: 20px;
    margin-right: 3em;
    display: flex;
    align-items: center;
}

@media (max-width: 730px) {
    .wizard-sidebar {
        transform: translateX(-112%);
    }

    .wizard-wrapper {
        width: 100%;
        margin-left: 0;
        justify-content: center;
        padding-right: 0;
    }

    .wizard-bottom-bar {
        width: 100%;
    }
}

.hidden {
    display: none;
}

.wizard-container {
    display: grid;
    justify-items: flex-start;
    align-self: center;
    margin-left: 4em;
}

.wizard-form {
    gap: 20px;
    display: grid;
}

.wizard-subtitle {
    margin-top: .5em;
    margin-bottom: 2em;
    color: var(--colorNeutralForeground4);
    width: 50%;
}

.wizard-container .checkbox-label {
    margin-block-start: 0px;
    color: var(--colorNeutralForeground3);
}

.wizard-sidebar ul {
    list-style-type: none;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 1.1em;
}

.wizard-sidebar li.checked::before {
    content: "\2713";
    color: var(--colorPaletteGreenBorder2);
    display: inline-block;
    width: 1.4em;
    margin-left: -2em;
}

.wizard-sidebar li.pending {
    opacity: 0.3;
    margin-left: -0.55em;
}

.wizard-sidebar li.current {
    font-weight: 700;
    margin-left: -0.55em;
}

.menu-subtitle {
    padding-left: 0.4em;
    font-weight: var(--fontWeightBold);
    font-size: var(--fontSizeBase200);
    color: var(--colorNeutralForeground2);
}

.menu-item {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.layout-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.navbar-container {
    background-color: var(--colorNeutralBackground4);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 35px;
    z-index: 200;
}

.navbar-container-full {
    min-width: 260px;
}

.admin-container {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}

.content-overlay {
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 100;
}

.setup-title-container {
    display: grid;
    gap: 5px;
    padding: 10px 0px 20px;
}

.scrollable-content {
    height: 100%;
    overflow-y: auto;
    scrollbar-color: var(--colorNeutralStroke1Hover) var(--colorNeutralBackground2);
    scrollbar-width: thin;
}

.inline-content {
    padding-inline: 2em;
}

.two-view-content {
    padding-inline: 2em;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .two-view-content {
        flex-direction: column;
    }
}

.vertical-timeline-element-content {
    box-shadow: 0 3px 0 var(--colorNeutralStroke1) !important;
}

.my-requests-container {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
}

.my-requests-card {
    margin-block: 1em;
    width: fit-content;
    min-width: 700px;
    min-height: 400px;
    row-gap: 0;
}

.responsive-post {
    transition: width 0.3s ease;
}

@media screen and (min-width: 544px) {
    .responsive-post {
        width: 100%;
        max-width: 100%;
    }
}

@media screen and (min-width: 884px) {
    .responsive-post {
        width: 45em;
        max-width: 45em;
    }
}

.closed-request {
    padding: 2em;
    max-width: 500px;
}

@media (max-width: 768px) {
    .closed-request {
        padding: 0px
    }
}

.tab-container {
    display: flex;
    padding-left: 20px;
    position: fixed;
    width: 100%;
    height: 50px;
    background-color: var(--colorNeutralBackground2);
    border-bottom: 1px solid var(--colorNeutralBackground5);
    z-index: 99;
}

.tab-content {
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 60px;
}

.tab-loading {
    margin-top: -5.5;
    margin-left: 70;
    width: 60%
}

.first-letter {
    text-transform: capitalize;
}

[data-show-checkboxes="false"] .fui-DataGridSelectionCell {
    display: none !important;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    .flex-row {
        flex-direction: column;
        align-items: stretch;
    }

    .flex-row input {
        width: 100%;
    }

    .flex-row button {
        width: 100%;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-container {
    display: flex;
    align-items: center;
}

.flex-btns {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.form-container {
    display: grid;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    min-width: 280px;
}

.form-field {
    width: 90%;
}

.incident-details {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.incident-details-list-item {
    display: flex;
    flex-direction: column;
}

.fui-Card.summary-content {
    background-color: var(--colorNeutralCardBackgroundHover);
    width: fit-content;
    --fui-Card--border-radius: var(--borderRadiusXLarge);
    max-width: 700
}

.fui-Card.summary-content .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    color: var(--colorNeutralForeground3);
}

.fui-Card.summary-content .footer-content .note {
    color: var(--colorPaletteYellowForeground1);
    font-size: var(--fontSizeBase100);
    margin-right: 1em;
}

.fui-Card.summary-content .footer-content .icon {
    color: var(--colorPaletteYellowForeground1);
}

.incident-details .summary {
    color: var(--colorPaletteYellowForeground1);
}

.fui-Card.notes-content {
    background-color: var(--colorPaletteGreenBackground1);
    width: fit-content;
    --fui-Card--border-radius: var(--borderRadiusXLarge);
    max-width: 700
}

.status-bar {
    align-items: center;
    display: flex;
    margin-top: 3px;
}

.status-bar .additional-text {
    color: var(--colorNeutralForeground3);
}

.assigned-details {
    background-color: var(--colorNeutralCardBackgroundDisabled);
    border-radius: 15px;
    padding-top: 1em;
    padding-bottom: 2em;
    padding-inline: 1.5em;
    margin-bottom: 2em;
}

.assigned-details .items-detail {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    display: flex;
}

.assigned-details .items-detail .item {
    display: flex;
    flex-direction: column;
    margin-right: 1em;
}

.incident-details .notes {
    color: var(--colorPaletteGreenBackground3);
}

.incident-details .incident-date {
    color: var(--colorNeutralForeground3);
}

.incident-details .items-detail {
    margin-top: 25px;
    margin-bottom: 2em;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    display: flex;
}

.incident-details-list-item .items-detail {
    margin-top: 10px;
    margin-bottom: 1em;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    display: flex;
}

.incident-details .items-detail .item {
    display: flex;
    flex-direction: column;
    margin-right: 1em;
}

.incident-details-list-item .items-detail .item {
    display: flex;
    flex-direction: column;
    margin-right: 0.5em;
}

.pill-tag {
    border-radius: var(--borderRadiusXLarge);
    padding: 1px;
    white-space: nowrap;
}

.on-hold-text {
    font-style: italic;
    color: var(--colorNeutralForeground4) !important;
}

.open-text {
    font-style: normal;
    color: var(--colorBrandForeground2Pressed) !important;
}

.completed-text {
    color: var(--colorPaletteSteelForeground2) !important;
}

.canceled-text {
    color: var(--colorNeutralForegroundDisabled) !important;
}

.tag-status-On-Hold {
    color: var(--colorPaletteYellowForeground1) !important;
    background: var(--colorPaletteYellowBackground2) !important;
    padding-inline: 6px !important;
}

.tag-status-Canceled {
    color: var(--colorPaletteDarkOrangeForeground2) !important;
    background: var(--colorPaletteDarkOrangeBackground2) !important;
    padding-inline: 6px !important;
}

.tag-status-Completed {
    color: var(--colorPaletteTealForeground2) !important;
    background: var(--colorPaletteTealBackground2) !important;
    padding-inline: 6px !important;
}

.tag-status-Open {
    color: var(--colorPaletteGreenForeground1) !important;
    background: transparent !important;
    background: var(--colorPaletteGreenBackground1) !important;
    padding-inline: 6px !important;
}

.tag-status-Open .fui-Tag__primaryText,
.tag-status-Completed .fui-Tag__primaryText,
.tag-status-Canceled .fui-Tag__primaryText,
.tag-status-On-Hold .fui-Tag__primaryText {
    font-weight: 600;
}

.dot-priority {
    width: 10px;
    margin-right: 4px;
}

.dot-priority-Critical {
    color: var(--colorPaletteRedBackground3)
}

.dot-priority-High {
    color: var(--colorPaletteRedForegroundInverted)
}

.dot-priority-Moderate {
    color: var(--colorPaletteMarigoldBorderActive)
}

.dot-priority-Normal {
    color: var(--colorPaletteBlueForeground2)
}

.incident-placeholder {
    margin-top: -1em;
    margin-left: -0.5em;
    color: var(--colorBackgroundOverlay);
}

.accordion-header {
    text-transform: uppercase;
    color: var(--colorPalettePlatinumBorderActive);
    font-size: var(--fontSizeBase200);
    font-weight: var(--fontWeightBold);
}

.mbl-2 {
    margin-block: 2em;
}

.mb-1 {
    margin-bottom: 1.2em;
}

.accordion-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.accordion-container>.accordion-action {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0.5em;
}

.dark .fui-Card span {
    color: var(--colorNeutralForeground1);
}

.narrow {
    width: 900px;
    margin: 0 auto;
}

.page-padding {
    padding: 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 0.5em;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
}

.tag-container .child {
    margin-right: 5px;
    margin-bottom: 5px;
}

.tag-container .child:last-child {
    margin-right: 0;
}

.tag-buttom {
    margin-bottom: 1em !important;
    background: var(--colorPaletteLightGreenBackground2) !important;
    color: var(--colorPaletteLightGreenForeground2) !important;
}

.welcome.page>.narrow>img {
    margin: 0 auto;
    display: block;
    width: 200px;
}

.welcome.page>.narrow>ul {
    width: 80%;
    justify-content: space-between;
    margin: 4rem auto;
    border-bottom: none;
}

.welcome.page>.narrow>ul>li {
    background-color: inherit;
    margin: auto;
}

.welcome.page>.narrow>ul>li>a {
    font-size: 14px;
    min-height: 32px;
    border-bottom-color: rgb(98, 100, 167);
}

.center {
    text-align: center;
}

.sections>* {
    margin: 4rem auto;
}

.tabList {
    display: flex;
    flex-direction: column;
}

pre,
div.error {
    background-color: #e5e5e5;
    padding: 1rem;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin: 1rem 0;
    max-height: 200px;
    overflow-x: scroll;
    overflow-y: scroll;
    max-width: 732px;
}

pre.fixed,
div.error.fixed {
    height: 200px;
}

code {
    background-color: #e5e5e5;
    display: inline-block;
    padding: 0px 6px;
    border-radius: 3px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

code::-webkit-scrollbar,
pre::-webkit-scrollbar {
    display: none;
}

.contrast pre,
.contrast code,
.contrast div.error {
    background-color: #000000;
    border-color: #ffffff;
    border-width: thin;
    border-style: solid;
}

.dark pre,
.dark code,
.dark div.error {
    background-color: #1b1b1b;
}

.error {
    color: red;
}

.icon16 {
    font-size: 16px;
}

.icon24 {
    font-size: 24px;
}

.icon32 {
    font-size: 32px;
}

.icon48 {
    font-size: 48px;
}

.unassigned {
    font-style: italic;
    color: var(--colorNeutralForeground4);
}

.caseId {
    color: var(--colorBrandForeground1);
}

.attachmentIconItem {
    color: var(--colorNeutralForeground2);
    transform: rotate(136deg) translateY(-4px);
}

.attachment-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}

.attachment-container:hover {
    background: var(--colorSubtleBackgroundHover) !important;
}

.attachment-icon-link {
    color: var(--colorNeutralForeground4);
    margin: 10px
}

.no-matching {
    color: var(--colorNeutralForeground3);
    padding: 1em;
}

.root {
    display: flex;
    height: 100%;
    margin-top: 1em;
}

.assigned-content {
    flex: 1;
    overflow: auto;
    position: relative;
    padding-bottom: 2em;
}

.fwRzpx {
    display: none !important;
}

.prJHE {
    width: auto !important;
}

.fDKiyq,
.iHroEM.active {
    color: #4d4d4d !important;
}

.tags-grid {
    display: grid;
    grid-template-rows: repeat(1fr);
    justify-items: start;
    gap: 2px;
    max-width: 400px;
}

.tags-list {
    list-style-type: none;
    margin-bottom: var(--fluentui-spacing-vertical-xxs);
    margin-top: 0;
    padding-left: 0;
    display: flex;
    gap: .3rem;
    flex-wrap: wrap;
}

.input-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.input-field-group {
    width: 180px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    border: none;
    /* color: red; */
}

.input-field-group:hover {
    background-color: rgba(255, 255, 255, 1);
}

.billing-card {
    width: 100%;
    max-width: 32rem;
}

.billing-card .summary-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.billing-card .billing-summary {
    background-color: var(--colorNeutralBackground3);
    border-radius: 0.5rem;
    padding: 1rem;
}

.billing-card .bill-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.billing-card .summary-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.billing-card .label-summary {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.billing-card .indented-label {
    padding-left: 1.75rem;
}

.billing-card .text-right {
    text-align: right;
}

.billing-card .small-text {
    font-size: 0.875rem;
    margin-left: 0.25rem;
}

.billing-card .total-row {
    border-top: 1px solid var(--colorNeutralStroke1);
    padding-top: 1rem;
}

.billing-card .total-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.125rem;
}

.billing-card .additional-info {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.billing-card .info-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--colorNeutralForeground2);
}

.billing-card .info-description {
    font-size: 0.775rem;
    color: var(--colorNeutralForeground4);
}

.billing-card .info-description .link {
    font-size: 0.775rem;
}

.billing-card .font-medium {
    font-weight: 500;
}

.billing-card .font-semibold {
    font-weight: 600;
}